import React, {lazy, Suspense, useEffect} from 'react';

const TopMenu = lazy(() => import('./components/common/top_menus/defTopMenu'))
const MainMenu = lazy(() => import('./components/common/main_menus/defMainMenu'))
const FooterMenu = lazy(() => import('./components/common/footer_menus/defFooterMenu'))
const Breadcrumb = lazy(() => import('./components/common/breadcrumbs/defBreadcrumb'))
const BackToTop = lazy(() => import('./components/common/back_to_top/defBackToTop'))
const OneColumn = lazy(() => import('./components/sections/blog_filters/one_column/oneColumn'))
const OneColumnTwo = lazy(() => import('./components/sections/blog_filters/one_column/oneColumnTwo'))
const OneColumnThree = lazy(() => import('./components/sections/blog_filters/one_column/oneColumnThree'))
const OneColumnFour = lazy(() => import('./components/sections/blog_filters/one_column/oneColumnFour'))
const TwoColumn = lazy(() => import('./components/sections/blog_filters/two_column/twoColumn'))
const TwoColumnTwo = lazy(() => import('./components/sections/blog_filters/two_column/twoColumnTwo'))
const ThreeColumns = lazy(() => import('./components/sections/blog_filters/three_column/threeColumns'))
const ThreeColumnsTwo = lazy(() => import('./components/sections/blog_filters/three_column/threeColumnsTwo'))
// const ThreeColumnsThree = lazy(() => import('./components/sections/blog_filters/three_column/threeColumnsThree'))
const ThreeColumnsFour = lazy(() => import('./components/sections/blog_filters/three_column/threeColumnsFour'))
const FourColumns = lazy(() => import('./components/sections/blog_filters/four_column/fourColumns'))
const FourColumnsTwo = lazy(() => import('./components/sections/blog_filters/four_column/fourColumnsTwo'))
const Featured = lazy(() => import('./components/sections/blog_filters/featured/featuredPosts'))
const FeaturedTwo = lazy(() => import('./components/sections/blog_filters/featured/featuredPostsTwo'))
const FeaturedThree = lazy(() => import('./components/sections/blog_filters/featured/featuredPostsThree'))
const FeaturedFour = lazy(() => import('./components/sections/blog_filters/featured/featuredPostsFour'))
const FeaturedFive = lazy(() => import('./components/sections/blog_filters/featured/featuredPostsFive'))
const FeaturedSix = lazy(() => import('./components/sections/blog_filters/featured/featuredPostsSix'))
const Breaking = lazy(() => import('./components/sections/blog_filters/breaking_news/defBreakingNews'))
const BreakingTwo = lazy(() => import('./components/sections/blog_filters/breaking_news/breakingNewsTwo'))
const BreakingThree = lazy(() => import('./components/sections/blog_filters/breaking_news/breakingNewsThree'))
const DefAbout = lazy(() => import('./components/sections/about_section/defAboutSection'))
const AboutTwo = lazy(() => import('./components/sections/about_section/aboutSectionTwo'))
const DefClient = lazy(() => import('./components/sections/clients_section/defClientsSection'))
const DefContentSlider = lazy(() => import('./components/sections/content_slider_section/defContentSliderSection'))
const ContentSliderTwo = lazy(() => import('./components/sections/content_slider_section/contentSliderTwo'))
const ContentSliderThree = lazy(() => import('./components/sections/content_slider_section/contentSliderThree'))
const DefCount = lazy(() => import('./components/sections/count_section/defCountSection'))
const DefCta = lazy(() => import('./components/sections/cta_section/defCtaSection'))
const DefFaq = lazy(() => import('./components/sections/faq_section/defFaqSection'))
const FaqTwo = lazy(() => import('./components/sections/faq_section/faqSectionTwo'))
const DefFeaturedVideo = lazy(() => import('./components/sections/featured_video_section/defFeaturedVideo'))
const FeaturedVideoTwo = lazy(() => import('./components/sections/featured_video_section/featuredVideoTwo'))
const DefFeatures = lazy(() => import('./components/sections/features_section/defFeaturesSection'))
const FeaturesTwo = lazy(() => import('./components/sections/features_section/featuresSectionTwo'))
const DefGallery = lazy(() => import('./components/sections/gallery_section/defGallerySection'))
const GalleryTwo = lazy(() => import('./components/sections/gallery_section/gallerySectionTwo'))
const DefHero = lazy(() => import('./components/sections/hero_section/defHeroSection'))
const HeroTwo = lazy(() => import('./components/sections/hero_section/heroSectionTwo'))
const HeroThree = lazy(() => import('./components/sections/hero_section/heroSectionThree'))
const HeroFour = lazy(() => import('./components/sections/hero_section/heroSectionFour'))
const HeroFive = lazy(() => import('./components/sections/hero_section/heroSectionFive'))
const HeroSix = lazy(() => import('./components/sections/hero_section/heroSectionSix'))
const DefLeftRightImage = lazy(() => import('./components/sections/left_or_right_image_section/defLeftRightImageSection'))
const DefMembers = lazy(() => import('./components/sections/members_section/defMembersSection'))
const DefPortfolio = lazy(() => import('./components/sections/portfolio_section/defPortfolioSection'))
const DefPricing = lazy(() => import('./components/sections/pricing_section/defPricingSection'))
const DefServices = lazy(() => import('./components/sections/services_section/defServicesSection'))
const DefSkills = lazy(() => import('./components/sections/skills_section/defSkillsSection'))
const DefTestimonials = lazy(() => import('./components/sections/testimonials_section/defTestimonialsSection'))


const App = (props: any) => {
    return (
        <Suspense fallback={<div>Loading...</div>}>
            <TopMenu/>
            <MainMenu/>
            <Breadcrumb/>
            {/*<DefAbout/>*/}
            {/*<DefHero/>*/}
            {/*<AboutTwo/>*/}
            {/*<DefClient/>*/}
            {/*<DefContentSlider/>*/}
            {/*<ContentSliderTwo/>*/}
            {/*<ContentSliderThree/>*/}
            {/*<DefCount/>*/}
            {/*<DefCta/>*/}
            {/*<DefFaq/>*/}
            {/*<FaqTwo/>*/}
            {/*<DefFeaturedVideo/>*/}
            {/*<FeaturedVideoTwo/>*/}
            {/*<DefFeatures/>*/}
            {/*<FeaturesTwo/>*/}
            {/*<DefGallery/>*/}
            {/*<GalleryTwo/>*/}
            {/*<HeroTwo/>*/}
            {/*<HeroThree/>*/}
            {/*<HeroFour/>*/}
            {/*<HeroFive/>*/}
            {/*<HeroSix/>*/}
            {/*<DefLeftRightImage/>*/}
            {/*<DefMembers/>*/}
            {/*<DefPortfolio/>*/}
            {/*<DefPricing/>*/}
            {/*<DefServices/>*/}
            {/*<DefSkills/>*/}
            {/*<DefTestimonials/>*/}
            <Breaking/>
            <BreakingTwo/>
            <BreakingThree/>
            <OneColumn/>
            <OneColumn sidebar={true}/>
            <OneColumnTwo/>
            <OneColumnTwo sidebar={true}/>
            <OneColumnThree/>
            <OneColumnThree sidebar={true}/>
            <OneColumnFour/>
            <OneColumnFour sidebar={true}/>
            <TwoColumn/>
            <TwoColumn sidebar={true}/>
            <TwoColumnTwo/>
            <TwoColumnTwo sidebar={true}/>
            <Featured/>
            <Featured sidebar={true}/>
            <FeaturedTwo/>
            <FeaturedTwo sidebar={true}/>
            <FeaturedThree/>
            <FeaturedThree sidebar={true}/>
            <FeaturedFour/>
            <FeaturedFour sidebar={true}/>
            <FeaturedFive/>
            <FeaturedFive sidebar={true}/>
            <FeaturedSix/>
            <FeaturedSix sidebar={true}/>
            <ThreeColumns/>
            <ThreeColumns sidebar={true}/>
            <ThreeColumnsTwo/>
            <ThreeColumnsTwo sidebar={true}/>
            {/*<ThreeColumnsThree/>*/}
            {/*<ThreeColumnsThree sidebar={true}/>*/}
            <ThreeColumnsFour/>
            <ThreeColumnsFour sidebar={true}/>
            <FourColumns/>
            <FourColumns sidebar={true}/>
            <FourColumnsTwo/>
            <FourColumnsTwo sidebar={true}/>
            <FooterMenu/>
            <BackToTop/>
        </Suspense>
    );
}

export default App;
